<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import debounce from 'lodash-es/debounce'
import BaseInput from '/~/components/base/input/base-input.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseButtonRound from '/~/components/base/button-round/base-button-round.vue'
import ButtonIcon from '/~/components/base/button/icon/button-icon.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import { useRecProfile } from '/~rec/composables/profile'

export default {
  name: 'rec-members-manager-modal',
  components: {
    BaseInput,
    BasePlaceholder,
    BaseButton,
    BaseButtonRound,
    BaseIcon,
    BaseAvatar,
    BaseMdl,
    HeaderMobile,
    ButtonIcon,
  },
  props: {
    title: {
      type: String,
      default: 'Members',
    },
    source: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    status: {
      type: [String, Boolean],
      default: false,
      validator: (v) => !v || /active|left/.test(v),
    },
    features: {
      type: String,
      default: '',
    },
    filterAvailableUsers: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const { myRecId } = useRecProfile()

    return {
      myRecId,
      modal,
      ui,
    }
  },
  data() {
    return {
      searchValue: '',
      selectedMember: null,
      itemToDelete: null,
      processing: false,
    }
  },
  computed: {
    members() {
      const list =
        this.source.members.hits || this.source.activeMembersWithoutMe

      return this.filterAvailableUsers
        ? list.filter(this.filterAvailableUsers)
        : list
    },
    filteredMembers() {
      const lowerSearchValue = this.searchValue.toLowerCase()

      return this.members.filter((hit) => {
        const lowerName = hit.fullName.toLowerCase()

        return (
          lowerName.indexOf(lowerSearchValue) >= 0 &&
          (this.status ? hit.status === this.status : true)
        )
      })
    },
    showStatus() {
      return this.features.includes('status')
    },
  },
  created() {
    const { members } = this

    if (members.hits && !this.members.allRecordsLoaded) {
      this.members.getData({ perPage: 500, force: false })
    }
  },
  methods: {
    onSearchInput: debounce(function (searchValue) {
      this.searchValue = searchValue
    }, 150),
    onMemberSelect(member) {
      if (this.selectable) {
        this.selectedMember = member
      }
    },
    onMemberTrash(member) {
      this.processing = false
      this.itemToDelete = member
    },
    onSave() {
      modal.hide()
      this.$emit('save', this.selectedMember)
    },
    onMemberDelete(member) {
      this.processing = true
      this.$emit('delete', member)
    },
    onAdd() {
      modal.show('rec-users-manager', {
        props: {
          title: 'Manage Members',
          fromGroup: true,
          selectable: true,
          multi: true,
          showAddList: true,
          getCurrentUsers: () => this.members,
        },
        on: {
          'user-select': (users) => {
            this.$emit('save', users)
          },
        },
      })
    },
  },
}
</script>

<template>
  <base-mdl
    v-bind="$attrs"
    :title="title"
    fullscreen="mobile"
    :width="ui.mobile ? 'screen' : 'xs'"
    transition="slide-right"
    :height="ui.mobile ? '' : 'md'"
    :padding="false"
  >
    <!-- Mobile Search -->
    <template v-if="ui.mobile" #header>
      <header-mobile v-bind="$attrs" :title="title" @back="modal.hide()">
        <template #bottom>
          <base-input
            :value="searchValue"
            class="mx-5 mb-5 pt-0"
            look="recroom"
            icon="plain/search"
            placeholder="Search member"
            icon-plain
            clearable
            @input="onSearchInput"
          />
        </template>
      </header-mobile>
    </template>
    <!-- Descktop Search -->
    <template v-else #header-bottom>
      <div class="mb-5 flex items-center px-[30px]">
        <base-input
          :value="searchValue"
          class="mb-0 w-full pt-0"
          look="recroom"
          icon="plain/search"
          placeholder="Search member"
          icon-plain
          clearable
          autofocus
          @input="onSearchInput"
        />
        <base-button-round class="-mr-[5px] ml-5" icon="plus" @click="onAdd" />
      </div>
    </template>

    <base-placeholder
      v-if="members.length === 0 && members.processing"
      :height="60"
      :rows="2"
      type="text"
      class="px-[30px]"
      rounded
    />
    <div class="grow overflow-y-auto pb-5">
      <div v-if="filteredMembers.length === 0" class="text-center">
        No members
      </div>
      <div
        v-for="member in filteredMembers"
        :key="member.id"
        class="flex cursor-default items-center"
        :class="{
          'px-5': itemToDelete !== member,
        }"
      >
        <div
          v-if="itemToDelete === member"
          class="my-2.5 flex h-12 w-full items-center bg-fg-error px-[30px] text-white"
          :class="{
            'pointer-events-none opacity-50': processing,
          }"
        >
          <b>Are you sure?</b>
          <div class="-mr-[5px] ml-auto flex items-center">
            <base-icon
              svg="rec/close-round"
              class="cursor-pointer"
              size="xl"
              @click="itemToDelete = null"
            />
            <button-icon
              icon="rec/success-round"
              class="ml-[5px] cursor-pointer"
              size="xl"
              @click="onMemberDelete(member)"
            />
          </div>
        </div>
        <div
          v-else
          class="flex w-full items-center rounded-lg p-2.5"
          :class="{
            'cursor-pointer hover:bg-dark': selectable && member.id !== myRecId,
          }"
          @click="member.id !== myRecId && onMemberSelect(member)"
        >
          <div class="mr-5 h-12 w-12">
            <div v-if="selectedMember === member" class="text-fg-success">
              <base-icon class="h-full w-full" svg="success" size="3xl" />
            </div>
            <base-avatar
              v-else
              size="lg"
              :src="member.avatarUrl"
              :abbr="member.initials"
            />
          </div>
          <b class="truncate">
            {{ member.fullName }}
          </b>

          <div class="-mr-[5px] ml-auto flex items-center pl-5">
            <div v-if="showStatus && member.status">
              <base-icon
                v-if="member.status === 'yes'"
                svg="rec/check-circle"
                size="md"
                class="block text-fg-success"
              />
              <base-icon
                v-else-if="member.status === 'no'"
                svg="rec/error-circle"
                size="md"
                class="block text-fg-error"
              />
            </div>
            <base-button
              v-if="editable && member.id !== myRecId"
              icon="trash"
              class="ml-2.5 block text-primary"
              @click="onMemberTrash(member)"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div v-if="selectable" class="flex">
        <base-button
          class="grow rounded-none"
          :disabled="!selectedMember"
          @click="onSave"
        >
          Save
        </base-button>
      </div>
    </template>
  </base-mdl>
</template>
